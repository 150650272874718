import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const CategoryTabs = ({ headerdetail }) => {

    return (<>
        {headerdetail?.tabs && headerdetail?.tabs?.length > 0 && <section className="section-gap-medium text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center">
                            <h2>{headerdetail.title}</h2>
                            <div className='catfooterTabs'>
                            <Tabs
                                defaultActiveKey={headerdetail?.tabs[0]?.tab_name}
                                id="justify-tab-example"
                            >
                                {headerdetail?.tabs && headerdetail?.tabs?.map((item, index) => (
                                    item.tab_description && (
                                        <Tab eventKey={item?.tab_name} title={item?.tab_name} key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: item?.tab_description }}></div>
                                        </Tab>
                                    )
                                ))}
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>}
    </>)
}

export default CategoryTabs