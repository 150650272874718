import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const CustomTabs = ({ dataObj }) => {

    return (<>
        {dataObj?.tabs && dataObj?.tabs?.length > 0 && 
            <Tabs
                defaultActiveKey={dataObj?.tabs[0]?.home_ta_title}
                id="justify-tab-example"
            >
                {dataObj?.tabs && dataObj?.tabs?.map((item, index) => (
                    item.home_ta_content && (
                        <Tab eventKey={item?.home_ta_title} title={item?.home_ta_title} key={index}>
                            <div dangerouslySetInnerHTML={{ __html: item?.home_ta_content }}></div>
                        </Tab>
                    )
                ))}
            </Tabs>
        }
    </>)
}

export default CustomTabs