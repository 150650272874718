import { BrowserView, MobileView } from "react-device-detect"
import constant from "../Service/constant"
import Skeleton from "react-loading-skeleton"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation, Scrollbar } from 'swiper/modules';
import React, { useRef,  useCallback } from "react";
import 'react-loading-skeleton/dist/skeleton.css'

const ProductGallery = ({ galleryImage, productname, loading }) => {
  const slidermobRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!slidermobRef.current) return;
    slidermobRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!slidermobRef.current) return;
    slidermobRef.current.swiper.slideNext();
  }, []);
  
  return (
    <>
     <BrowserView>
     { galleryImage && galleryImage?.length > 0 && (
        <div className="product-gallery">
            {galleryImage?.map((item, index) => (
                <img 
                    src={item ? item : constant.DEFAULT_IMAGE} 
                    key={index} 
                    alt={productname} 
                    className="product-image"
                />
            ))}
        </div>
    )}

     {/* {loading ? (
    [...Array(6)].map((item, index) => (
      <Skeleton width={'200px'} height={'1000px'} key={index}></Skeleton>
    ))
) : (
   
)} */}
     </BrowserView>
       
     
      <MobileView>
        {galleryImage && galleryImage?.length > 0 &&
          <div className="gallerySlider"> 
          <Swiper
            ref={slidermobRef}
            spaceBetween={20}
            modules={[Navigation, Scrollbar,]}
            slidesPerView={1}
          >
            {galleryImage.map((item, index) => {
              return (

                <SwiperSlide>
                  <img src={item ? item : constant.DEFAULT_IMAGE} key={index} alt={productname}></img>
                </SwiperSlide>
              )
            }
            )}
          </Swiper>
            <div className='swiper-arrow'>
              <div className="prev-arrow" onClick={handlePrev}><i class="ri-arrow-left-s-line"></i></div>
              <div className="next-arrow" onClick={handleNext}><i class="ri-arrow-right-s-line"></i></div>
            </div>
          </div>
        }
      </MobileView>

    </>)

}

export default ProductGallery