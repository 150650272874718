import React, { useContext, useEffect, useRef } from 'react'
import constant from '../Service/constant'
import multiCurrency from './multiCurrency';
import DataContext from './context';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiService } from '../Service/apiservices';
import { addToCart, addToCartSession } from './add_to_cart';
function ProductBox({ productValue, classType }) {
  const contextValues = useContext(DataContext)
  const didMountRef = useRef(true)
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {

    }
    didMountRef.current = false
  })
  const variationModal = () => {
    contextValues.setProductData(productValue)
    setTimeout(() => {
      contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }, 100);
  }

  const addtofav = (addproduct) => {
    const productData = {
      product_id: Number(addproduct.product_id),
    };
    ApiService.postData("addToFavList", productData).then((res) => {
      if (res.data.status == "success") {
        var element = document.getElementById("wishlisticon" + addproduct.product_id);
        element.classList.remove("ri-heart-fill", "ri-heart-line");
        element.classList.add(res.data.notification);
        contextValues.setFavCount(res.data.count)
        if (res.data.notification === "ri-heart-3-line") {
          toast.success('Removed from wishlist');
        } else {
          toast.success('Added to Wishlist');
        }
      }
    });
  };

  const addToCartProcess = async (addproduct) => { 
    if (parseFloat(addproduct.product_selling_price) > 0) { 
      contextValues.setSpinnerCubLoader(addproduct.product_id)
      if (contextValues.userToken) {
        const updateStatus = await addToCartSession(addproduct.variation, 1, contextValues);
        if (updateStatus) {
          contextValues.setSpinnerCubLoader(0)
          cartModal()
        } else {
          contextValues.setSpinnerCubLoader(0)
        }
      } else {
        const updateStatus = await addToCart(addproduct.variation, 1, contextValues);
        if (updateStatus) {
          contextValues.setSpinnerCubLoader(0)
          cartModal()
        } else {
          contextValues.setSpinnerCubLoader(0)
        }
      }
    } else {
      toast.error('Internal Error, Please try later.');
    }
  };

  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }
  return (
    <div className={classType} key={1}>
      <div className='product-media-shap'>
        <figure className='product-media'>
          <a href={"/product/" + productValue.product_slug}>
            {productValue.arrayImages && productValue.arrayImages.length >= 2 ?
              <>
                <img src={productValue.arrayImages[0]} alt={productValue.product_name} />
                <img src={productValue.arrayImages[1]} alt={productValue.product_name} />
              </>
              :
              <>
                <img src={productValue.arrayImages[0]} alt={productValue.product_name} />
                <img src={productValue.arrayImages[0]} alt={productValue.product_name} />
              </>
            }
          </a>
        </figure>

        <div className='product-action-vertical'>
          {contextValues.userToken ? (
            productValue.ufp_id > 0 ? (
              <a href="javascript:void(0)" className="btn-product-icon btn-wishlist" title="Add to wishlist" onClick={(e) => addtofav(productValue)}><i className="ri-heart-fill" id={"wishlisticon" + productValue.product_id}></i></a>
            ) : (
              <a href="javascript:void(0)" className="btn-product-icon btn-wishlist" title="Add to wishlist" onClick={(e) => addtofav(productValue)}><i className="ri-heart-line" id={"wishlisticon" + productValue.product_id}></i></a>
            )
          ) : (
            <a href="javascript:void(0)" className="btn-product-icon btn-wishlist" title="Add to wishlist" onClick={(e) => { navigate('/login') }}><i className="ri-heart-line"></i></a>
          )
          }
        </div>
        {productValue.product_label_name !== '' && productValue.product_label_name.split(', ').length > 0 &&
          <div className='product-label'>
            {productValue.product_label_name.split(',').map((label, indexLabel) => (
              <span className='label-new' key={indexLabel}>{label}</span>
            ))}
          </div>
        }
        {productValue.product_type == 0 ?
          <button className='addcartbutton' onClick={(e) => addToCartProcess(productValue)}><i className="ri-add-line"></i></button>
          :
          <button className='addcartbutton' onClick={(e) => variationModal()}><i className="ri-add-line"></i></button>
        }
      </div>
      <div className='product-details'>
        <h2 className="product-name"> <a href={"/product/" + productValue.product_slug}>{productValue.product_name}</a></h2>
        <div className="product-price">
          <ins className="new-price">{multiCurrency(productValue.product_selling_price)}</ins>
          {Number(productValue.product_price) > Number(productValue.product_selling_price) && (<del className="old-price">{multiCurrency(productValue.product_price)}</del>)}
        </div>
      </div>
    </div>
  )
}

export default ProductBox
