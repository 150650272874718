const ServiceFooter = () => {
  return (<>
    <section className="section-gap-md bg-primary" >
      <div className="container">
        <div className="section-title tx-center mb-40 ">
          <h2>Padmakshya Services</h2>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="iconVerticlebox tx-center">
              <img src="/img/service1.png" className="mb-20"></img>
              <h5>Unbeatable craftsmanship</h5>
              <p className="mb-0">Handcrafted with Love in India.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="iconVerticlebox tx-center">
              <img src="/img/service2.png" className="mb-20"></img>
              <h5>Secure Payment Options</h5>
              <p className="mb-0">Purchase your jewels securely with UPI,
                Debit/Credit card, Paytm & More</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="iconVerticlebox tx-center">
              <img src="/img/service3.png" className="mb-20"></img>
              <h5>Shipping Pan India</h5>
              <p className="mb-0">We deliver exquisite jewellery across India, ensuring that a
                touch of elegance is just a doorstep away.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>)
}


export default ServiceFooter