import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import React, { useContext, useState, useEffect, useRef } from 'react';
import { validEmail, validPassword } from '../../Component/Element/Regex';
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import Alert from 'react-bootstrap/Alert';
import sessionCartData from "../../Component/Element/cart_session_data";
import ServiceFooter from "../../Component/Footer/services_footer";



const Login = () => {
    const dataArray = sessionCartData();
    const navigate = useNavigate();
    const didMountRef = useRef(true)
    const [step, setStep] = useState(1);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loginData, setLoginData] = useState({
        user_email: "",
        user_password: ""
    })
    const [forgetPassword, setforgetPassword] = useState({
        userforget_email: "",
    });

    const handleLoginChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setLoginData(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const loginProcess = () => {

        let counter = 0;
        const loginElements = document.getElementsByClassName("loginRequired");
        for (let i = 0; i < loginElements.length; i++) {
            if (loginElements[i].value === '') {
                loginElements[i].style.border = '1px solid red';
                counter++;
            } else {
                loginElements[i].style.border = '';
            }
        }
        const dataString = {
            user_email: loginData.user_email,
            user_password: loginData.user_password,
            session_data: dataArray[1],
        }

        if (counter == 0) {
            if (!validEmail.test(loginData.user_email)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }
            // if (!validPassword.test(loginData.user_password)) {
            //     setErrorMessage('Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
            //     return false;
            // }
            setButtonLoader(true)
            ApiService.loginProccessPostData("logincheck", dataString).then((res) => {
                if (res.status == "success") {
                    localStorage.setItem("USER_TOKEN", res.user_token)
                    setTimeout(() => {
                        setSuccessMessage(res.message);
                        setButtonLoader(false)
                        window.location.href = '/';
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            }).catch(()=>{
                setButtonLoader(false)
            })
        }
    }
    const clearErrorStyles = () => {
        const allInputs = document.querySelectorAll('input');
        allInputs.forEach(input => {
            input.style.border = '';
        });
    }

    const onChangeStep = (step) => {
        setStep(step)
        clearErrorStyles()
        setErrorMessage('')
        setforgetPassword({ userforget_email: '' })
        setLoginData({
            user_email: "",
            user_pass: "",
        })

    }


    const userForgetProcess = () => {
        setErrorMessage("");
        let counter = 0;
        const forgotElements = document.getElementsByClassName("userForgotRequired");
        for (let i = 0; i < forgotElements.length; i++) {
            if (forgotElements[i].value === '') {
                forgotElements[i].style.border = '1px solid red';
                counter++;
            } else {
                forgotElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            if (!validEmail.test(forgetPassword.userforget_email)) {
                setErrorMessage("Invalid Email Format");
                return false;
            }
            setButtonLoader(true)
            const dataString = {
                "user_email": forgetPassword.userforget_email,
            };
            ApiService.loginProccessPostData('forgotpassword', dataString).then((res) => {
                if (res?.status == 'success') {
                    setTimeout(() => {
                        setButtonLoader(false)
                        setStep(3)
                    }, 500);
                } else {
                    setTimeout(() => {
                        setErrorMessage(res.message);
                        setButtonLoader(false)
                    }, 500);
                }
            }).catch((error) => {
                setButtonLoader(false)
            })
        } 
    }
    return (<>
        <Header innerHeader={'innerHeader'}></Header>

        <section>
            <div className="container-fluid px-0">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/img/loginpadma.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        {step == 1 ? <>
                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <h4>SIGN IN</h4>
                                    <p>If you already have an account with us, you can sign in here</p>
                                </div>
                                {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                                {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                                <div className="form-group mb-15">
                                    <input type="email" placeholder="Email Address" className="loginRequired" name='user_email' value={loginData.user_email} onChange={(e) => { handleLoginChange(e) }} />
                                </div>
                                <div className="form-group mb-15">
                                    <input type="password" placeholder="Password" className="loginRequired" name='user_password' value={loginData.user_password} onChange={(e) => { handleLoginChange(e) }} />
                                </div>
                                <div className="form-group mb-15 tx-right">
                                    <a className="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={() => { onChangeStep(2) }}>Forgot Password</a>
                                </div>
                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" disabled={buttonLoader} onClick={() => { loginProcess() }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("SIGN IN")}</button>
                                <div className="tx-center">
                                    <p>Don't have an account?</p>
                                    <a className="btn btn-underline-primary p-0" href="/register">Create an account</a>
                                </div>

                            </div>



                        </> : step == 2 ? <>
                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <h4>Forgot Password</h4>
                                    <p>No problem. Enter your email, We’ll send you a verification link to reset your password.</p>
                                </div>
                                {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                                {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                                <div className="form-group mb-15">
                                    <input type="email" placeholder="Email Address" className="userForgotRequired" name='userforget_email' value={forgetPassword.userforget_email} onChange={(e) => {
                                setforgetPassword((prevState) => ({
                                    ...prevState,
                                    'userforget_email': e.target.value,
                                }));
                                setErrorMessage('')
                            }} />
                                </div>

                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" disabled={buttonLoader} onClick={() => { userForgetProcess() }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("SEND EMAIL")}</button>
                                <div className="tx-center">
                                    <p>Already have an account?</p>
                                    <a className="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={()=>{onChangeStep(1)}}>Sign in</a>
                                </div>
                            </div>

                        </> : step == 3 ? <>
                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <img src="/img/verify_mail.png" className="wd-120 mb-20" />
                                    <p style={{ textAlign: 'center' }}>Please Check Your email inbox for the password reset link.</p>
                                </div>

                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}>Go To Home</button>
                            </div>



                        </> : null}

                    </div>
                </div>
            </div>
        </section>
        <ServiceFooter></ServiceFooter>
        <Footer></Footer>
    </>)
}

export default Login