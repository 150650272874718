
import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../Element/context';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../Service/apiservices';
import Skeleton from 'react-loading-skeleton';
import constant from '../Service/constant';
import multiCurrency from '../Element/multiCurrency';
import sessionCartData from '../Element/cart_session_data';
import Loader from "react-js-loader";
import { addToCart, addToCartSession, minusToCart, minusToCartSession, removeToCart, removeToCartSession } from '../Element/add_to_cart';
import { toast } from "react-toastify";
const CartModal = () => {
    const dataArray = sessionCartData();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [recomendedProductList, setrecomendedProductList] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const didMountRef = useRef(true)

    const contextValues = useContext(DataContext)
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem("USER_TOKEN")) {
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
            getrecommendedProduct()
        }
        didMountRef.current = false;
    }, []);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: null,
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }

    const getrecommendedProduct = () => {
        setisLoading(true)
        ApiService.fetchData('/recommendedproductslist').then((res) => {
            if (res.status == 'success') {
                setrecomendedProductList(res.recommendedproducts)
                setisLoading(false)
            } else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }

    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: addproduct.product_image,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: addproduct.product_variation,
                product_category_id: addproduct.product_category_id,
                selected_variation: addproduct.selected_variation,
                product_availability_status: addproduct.product_availability_status,
                quantity: addproduct.quantity,
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const minusToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const removeToCartProcess = async (productData) => {
        setSpinnerLoading(false);
        if (localStorage.getItem("USER_TOKEN")) {
            const updateStatus = await removeToCartSession(productData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        } else {
            const updateStatus = await removeToCart(productData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        }
    };
    const addToCartRecomendedProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(addproduct.variation, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    if(addproduct.product_type == 1){
                        cartModal()
                    }
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(addproduct.variation, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    if(addproduct.product_type == 1){
                        cartModal()
                    }
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const variationModal = (productValue) => {
        contextValues.setProductData(productValue)
        setTimeout(() => {
            contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
            cartModal()
        }, 100);
    }
    return (<>
        <Modal show={contextValues.toggleCartModal} onHide={(e) => cartModal()} className='right cartmodal'>
            {isLoading ? <>
                <div className='cartmodalRecomd'>
                    <div className='cartmodalRecomdHeader'>
                        <h5 className='mb-0'><Skeleton></Skeleton></h5>
                    </div>
                    {[...Array(6)].map((item, index) => {
                        return (<>
                            <div className='cartmodalRecomdBody' key={index}>
                                <div className='product mb-10'>
                                    <a href='javascript:void(0)' className='product-media-shap'>
                                        <figure className='product-media'>
                                            <Skeleton width={'200px'} height={'200px'}></Skeleton>
                                        </figure>
                                    </a>
                                    <div className="product-details">
                                        <h2 className="product-name">
                                            <a href="javscript:void(0)"><Skeleton width={'250px'}></Skeleton></a>
                                        </h2>
                                        <div className="product-price">
                                            <ins className="new-price"><Skeleton width={'50px'}></Skeleton></ins>
                                            <del className="old-price"><Skeleton width={'50px'}></Skeleton></del>
                                        </div>
                                        <Skeleton width={'100px'}></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
            </> : <>
                {recomendedProductList && recomendedProductList.length > 0 && (
                    <div className='cartmodalRecomd'>
                        <div className='cartmodalRecomdHeader'>
                            <h5 className='mb-0'>You might also like</h5>
                        </div>
                        <div className='cartmodalRecomdBody'>
                            {recomendedProductList.map((item, index) => {
                                return (<>
                                    <div className='product mb-10' key={index}>
                                        <a href={`/product/${item.product_slug}`} className='product-media-shap'>
                                            <figure className='product-media'>
                                                <img src={item.product_image ? item.product_image : constant.DEFAULT_IMAGE} alt={item.product_name} width="280" height="315" />
                                            </figure>
                                        </a>
                                        <div className="product-details">
                                            <h2 className="product-name">
                                                <a href={`/product/${item.product_slug}`}>{item.product_name}</a>
                                            </h2>
                                            <div className="product-price">
                                                <ins className="new-price">{multiCurrency(item.product_selling_price)}</ins>
                                                <del className="old-price">{multiCurrency(item.product_price)}</del>
                                            </div>
                                            {item.product_type == 0 ?
                                                <button className='btn btn-primary-line' onClick={(e) => addToCartRecomendedProcess(item)}>Add to Cart</button>
                                                :
                                                <button className='btn btn-primary-line' onClick={(e) => variationModal(item)}>Add to Cart</button>
                                            }
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                )}
            </>}

            <div className='cartmodalBody'>
                <div className='cartmodalHeader'>
                    <h5 className='mb-0 tx-uppercase'>shopping bag {contextValues.cartSessionData && contextValues.cartSessionData.length > 0 && (contextValues.cartSessionData.length)}</h5>
                    <button type="button" className="cart-close" onClick={() => { cartModal() }}><i className="ri-close-line ri-xl"></i></button>
                </div>
                {spinnerLoading && <div className='cartModalLoader'><Loader type="spinner-default" bgColor={'#404041'} color={'#404041'} size={50} /> </div>}
                {contextValues.cartSessionData.length > 0 ?
                    <>
                        <div className='cartmodal-scroll'>
                            {contextValues.cartSessionData.map((value, index) => {
                                return (
                                    <div className='cartProduct d-flex' key={index}>
                                        <a href={'javascript:void(0)'} className="cartProductRemove" onClick={(e) => removeToCartProcess(value)}><i className="ri-delete-bin-6-line"></i></a>
                                       <div className='cartProductMedia-shap'>
                                        <figure className='cartProductMedia mr-15'>
                                            <img src={value.product_image}></img>
                                        </figure>
                                        </div>
                                        <div className='cartProductDetails'>
                                            <h2 className='title'><a href={'/product/' + value.product_slug}>{value.product_name}</a></h2>
                                            {value.product_type == 1 && value.selected_variation && value.selected_variation.length > 0 ?
                                                <>
                                                    {value.selected_variation.map((value, index) => {
                                                        return (<div className='tx-gray-500 mb-10 tx-13' key={index}>{value.attr}: {value.terms}</div>)
                                                    })}
                                                </>
                                                : null}
                                               <div className="cartProduct-footer">
                                                <div className="cartProduct-price">
                                                    <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                                                    {Number(value.product_price) > Number(value.product_selling_price) && (<del className="old-price">{multiCurrency(value.product_price)}</del>)}
                                                </div>
                                                <div className="cartProductqty">
                                                    <span onClick={(e) => minusToCartProcess(value)}><i className="ri-subtract-line"></i></span>
                                                    <span>{contextValues.spinnerCubLoader == value.product_id ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={14} /> </div> : value.quantity}</span>
                                                    <span onClick={(e) => addToCartProcess(value)}><i className="ri-add-line"></i></span>
                                                </div>
                                            </div>
                                            {value.product_availability_status == 0 && (<p className="tx-12 mt-1 line16 tx-primary">If the design is not readily available it will be made to order, which will take us approximately 25-30 days</p>)}
                                            
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='cartmodal-footer'>
                            <div className='cartmodal-total'>
                                <div>
                                    <p className="mb-0">Estimated Total </p>
                                    <p className="mb-0 tx-12 tx-gray-500">Shipping & taxes calculated at checkout</p>
                                </div>
                                <span className="total-price">{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                            </div>
                            <button className="btn btn-lg btn-primary btn-full tx-uppercase mt-10" onClick={() => { navigate('/cart'); cartModal() }}>View my shopping bag</button>
                        </div>
                    </>
                    :
                    <div className='cartmodal-scroll'>
                        <div className='noimg'>
                            <img src='/img/emptycart.png' className='wd-150' alt='cart'></img>
                            <h5>Your Cart is Empty</h5>
                            {/* <p>It looks like you haven't added any item to your cart yet.</p> */}
                            <p>Your cart is waiting for items!</p>
                            <a href='/' className='btn btn-primary-outline'>Continue Shopping</a>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    </>)
}


export default CartModal