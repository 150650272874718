import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSubHeader from "../../Component/Header/account_subheader";
import React, { useEffect, useRef, useState } from "react";
import multiCurrency from "../../Component/Element/multiCurrency";
import { useParams } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import moment from "moment";
import OrderCancelModal from "../../Component/Modal/order_cancel_modal";






const OrderDetail = () => {
  const didMountRef = useRef(true);
  const { id } = useParams()
  const [orderDetail, setOrderDetail] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [transId, setTransId] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useState()
  const handleOpenModal = (status, transid) => {
    setTransId(transid);
    setShowCancelModal(status);
  };

  const handleCancelModal = () => {
    setShowCancelModal(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      getOrderData();
    }
    didMountRef.current = false;
  }, []);

  const getOrderData = () => {
    const dataString = {
      trans_id: id,
    };
    ApiService.postData("get-order-detail", dataString).then((res) => {
      if (res.status === "success") {
        setOrderDetail(res.row_orders_data);
        setItemsData(res.row_orders_data.items);
      } else if (res.message == "Session expired") {
        localStorage.removeItem("USER_TOKEN");
        navigate("/");
        setLoading(false);
      }
      setLoading(false);
    });
  };




  return (<>
    <Header innerHeader={'innerHeader'}></Header>
    <BrowserView>

      <AccountSubHeader breadcrumbtitle={'Order Detail'}></AccountSubHeader>

      <section className="section-gap-medium">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <AccountSidebar></AccountSidebar>
            </div>
            <div className="col-lg-9">
              <div className="section-title mb-30">
                <h2>Order Detail</h2>
              </div>
              <div>
                {orderDetail && orderDetail.trans_id > 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="order-box">
                        <div className="info">
                          <div className="info-delivery">
                            <h6 className="mb-1">Delivery Address</h6>
                            <h6>{orderDetail.trans_user_name}</h6>
                            <p className="tx-14 mb-1">{orderDetail.trans_delivery_address}</p>
                            {orderDetail?.trans_user_email && <p className="tx-14 mb-1">
                              Email Id :
                              {orderDetail.trans_user_email}
                            </p>}
                            <p className="tx-14 mb-0">
                              Phone number :
                              {orderDetail.trans_user_mobile}
                            </p>
                            <p className="tx-14 mb-0 mt-3">
                              Order Status :
                              <span className="badge bg-success ms-3">
                                {orderDetail.orderstatus}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="bcode">
                          <div className="orderid-box mb-20">
                            <h6 className="mb-0">ORDER ID</h6>
                            <p className="mb-0 tx-14 fw400">{orderDetail.trans_order_number}</p>
                          </div>
                          <p className="mb-0 tx-13">ORDER ON</p>
                          <p className="tx-14">
                            {moment(orderDetail.trans_datetime).format("ddd, DD MMM YYYY")}
                            <br />
                            {moment(orderDetail.trans_datetime, "HH:mm").format("hh:mm A")}
                          </p>
                          {Number(orderDetail.trans_status) == 0 ? (
                            <a
                              href="javascript:void(0)"
                              onClick={(e) =>
                                handleOpenModal(true, orderDetail.trans_id)
                              }
                              className="btn btn-primary btnw-200"
                            >
                              Cancel Order
                            </a>
                          ) : null}
                        </div>
                      </div>
                      <div className="card-table">
                        <div className="card-table-header">Order Items List</div>
                        <div className="card-table-section">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th className="text-center">S.No</th>
                                <th>Image</th>
                                <th>Title</th>
                                <th className="text-center">QTY</th>
                                <th className="text-center">Price</th>
                                <th className="text-center">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {itemsData.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">
                                      <img
                                        src={value.td_item_image}
                                        alt={value.td_item_title}
                                        style={{ width: "35px" }}
                                      />
                                    </td>
                                    <td>
                                      {value.td_item_title}
                                      <br />

                                    </td>
                                    <td className="text-center">
                                      {value.td_item_qty}
                                    </td>
                                    <td className="text-center">
                                      {multiCurrency(value.td_item_sellling_price)}
                                    </td>
                                    <td className="text-center">
                                      {multiCurrency(value.td_item_net_price)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colspan="3"></td>
                                <td colspan=""></td>
                                <td>Sub Total</td>
                                <td className="text-center">
                                  {multiCurrency(orderDetail.item_sub_total)}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>Discount</td>
                                <td className="text-center">
                                  -{multiCurrency(orderDetail.trans_discount_amount)}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>Coupon Discount</td>
                                <td className="text-center">
                                  {multiCurrency(orderDetail.trans_coupon_dis_amt)}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>Delivery Charges</td>
                                <td className="text-center">
                                  {multiCurrency(orderDetail.trans_delivery_amount)}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4"></td>
                                <td>
                                  <strong>Grand total</strong>
                                </td>
                                <td className="text-center">
                                  <strong>
                                    {multiCurrency(orderDetail.trans_amt)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                  </div>
                ) : (
                  !loading && (
                    <div>
                      <h4>No Orders Found!</h4>
                      <p><a class="btn btn-underline-primary p-0" href="/" >Shop Now <i class="ri-arrow-right-line"></i></a></p>
                    </div>
                  )
                )}
              </div>

            </div>
          </div>
        </div>
      </section>

    </BrowserView>
    <MobileView>
      <AccountSubHeader breadcrumbtitle={'Order Detail'}></AccountSubHeader>
      <section className="section-gap-small">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <AccountSidebar></AccountSidebar>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gap-small">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="section-title mb-30">
                <h2>Order Detail</h2>
              </div>
              {orderDetail && orderDetail.trans_id > 0 ? (
                <div className="acpanel-body">
                  <div className="order-box">
                    <div className="info">
                      <div className="info-delivery">
                        <h6 className="mb-0">ORDER ID</h6>
                        <p className="mb-0 tx-14 fw400">{orderDetail.trans_order_number}</p>
                        <p className="mb-0 tx-13">ORDER ON {moment(orderDetail.trans_datetime).format("ddd, DD MMM YYYY")} {moment(orderDetail.trans_datetime, "HH:mm").format("hh:mm A")}</p>
                        <h5 className="mb-3 fw400 mt-20">Delivery Address</h5>
                        <h6>{orderDetail.trans_user_name}</h6>
                        <p className="tx-14 mb-1">{orderDetail.trans_delivery_address}</p>
                        {orderDetail.trans_user_email && <p className="tx-14 mb-1">Email Id : {orderDetail.trans_user_email}</p>}
                        {orderDetail.trans_user_mobile && <p className="tx-14 mb-0">Mobile No : {orderDetail.trans_user_mobile}</p>}
                        <p className="tx-14 mb-1">
                          Order Status :
                          <span className="badge bg-success ms-2">
                            {orderDetail.orderstatus}
                          </span>
                        </p>
                        <p className="tx-14 mb-1">ORDER ON : <span className="tx-12">
                          {moment(orderDetail.trans_datetime).format(
                            "ddd, DD MMM YYYY"
                          )}
                        </span><span className="ms-2">
                            {moment(orderDetail.trans_datetime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </span></p>

                        {Number(orderDetail.trans_status) == 0 ? (
                          <a
                            href="javascript:void(0)"
                            onClick={(e) =>
                              handleOpenModal(true, orderDetail.trans_id)
                            }
                            className="btn btn-primary btnw-200"
                          >
                            Cancel Order
                          </a>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="card-table">
                    <div className="card-table-header">Order Items List</div>
                    <div className="card-table-section">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th className="text-center">S.No</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th className="text-center">QTY</th>
                            <th className="text-center">Price</th>
                            <th className="text-center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemsData.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">
                                  <img
                                    src={value.td_item_image}
                                    alt={value.td_item_title}
                                    style={{ width: "35px" }}
                                  />
                                </td>
                                <td>
                                  {value.td_item_title}
                                  <br />

                                </td>
                                <td className="text-center">
                                  {value.td_item_qty}
                                </td>
                                <td className="text-center">
                                  {multiCurrency(value.td_item_sellling_price)}
                                </td>
                                <td className="text-center">
                                  {multiCurrency(value.td_item_net_price)}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colspan="3"></td>
                            <td colspan=""></td>
                            <td>Sub Total</td>
                            <td className="text-center">
                              {multiCurrency(orderDetail.item_sub_total)}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                            <td>Discount</td>
                            <td className="text-center">
                              -{multiCurrency(orderDetail.trans_discount_amount)}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                            <td>Coupon Discount</td>
                            <td className="text-center">
                              {multiCurrency(orderDetail.trans_coupon_dis_amt)}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                            <td>Delivery Charges</td>
                            <td className="text-center">
                              {multiCurrency(orderDetail.trans_delivery_amount)}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                            <td>
                              <strong>Grand total</strong>
                            </td>
                            <td className="text-center">
                              <strong>
                                {multiCurrency(orderDetail.trans_amt)}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                !loading && (
                  <div>
                    <h4>No Orders Found!</h4>
                    <p><a class="btn btn-underline-primary p-0" href="/" >Shop Now <i class="ri-arrow-right-line"></i></a></p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </MobileView>
    <Footer></Footer>
    <OrderCancelModal
      transId={transId}
      show={showCancelModal}
      closeModal={handleCancelModal}
    />
  </>)
}

export default OrderDetail