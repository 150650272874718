import { useContext, useState } from "react"
import DataContext from "../../Element/context"
import { useNavigate } from "react-router-dom"

const TopHeader=()=>{
    const [Step , setStep] = useState('')
    const navigate = useNavigate()
    const contextValues= useContext(DataContext)
    const User_session= localStorage.getItem('USER_TOKEN')  
    return(<>
    
    <div className="container-fluid">
        <div className="headerLeft">
            <ul className="leftHeaderlist">
                <li><a href="/contact-us">Store</a></li>
            </ul>
        
        </div>
        {contextValues?.settingData?.header_marquee ?   <div className="headerMiddle tx-center">
        <marquee>{contextValues?.settingData?.header_marquee}</marquee>
        </div>:''} 
      
        <div className="headerRight justify-content-end">
                <ul className="htrlist">
                    {/* <li className="mr-50"><a href="/padmakshya-club">Padmakshya Clubs</a></li> */}
                    {!User_session && User_session==null ?<>
                        <li><a href="/login">Login</a></li>
                    <span className="divider mt-1"></span>
                    <li><a href="/register" >Register</a></li>
                    
                    </>:<> <li><a href="/my-account" >My Account</a></li>
                   </>}
                  
                   <li className="wishlist">
                   <a href="/account/wishlist">
                       <i className="ri-heart-line ri-lg"></i>
                       {Number(contextValues.favCount) > 0 &&
                       <span className="count">{contextValues.favCount}</span>}
                       </a>
                      
                   </li>
                
                    
                </ul>
        </div>
    </div>
   
    
    </>)
}

export default TopHeader