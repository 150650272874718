import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useContext } from "react";
import DataContext from "../../Component/Element/context";
import { useNavigate } from "react-router-dom";
import AccountSubHeader from "../../Component/Header/account_subheader";
import React, { useRef, useState, useEffect } from "react";
import { ApiService } from "../../Component/Service/apiservices";
import moment from "moment";

const MyAccount = () => {
  const contextValues = useContext(DataContext)
  const navigate = useNavigate()


  const navigateback = (e) => {
    e.preventDefault()

    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const didMountRef = useRef(true);
  const [rowUserData, setRowUserData] = useState({});
  const [orderData, setOrderData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  useEffect(() => {
    if (didMountRef.current) {

      getorderData();

    }
    didMountRef.current = false;
  }, []);
  const getorderData = () => {
    ApiService.fetchData("recent-order").then((res) => {
      if (res.status == "success") {
        setOrderData(res.resOrderData);
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });


  };

  const Step = ({ status, date, statustext }) => {
    const stepClasses = `col-3 bs-wizard-step ${status === 'active' ? 'active' : ''} ${status === 'complete' ? 'complete' : ''
      } ${status === 'disabled' ? 'disabled' : ''}`;

    return (
      <div className={stepClasses}>
        <div className="progress">
          <div className="progress-bar"></div>
        </div>
        <a href="#" className="bs-wizard-dot"></a>
        <div className="bs-wizard-info text-center">
          <p className="mb-0">{statustext}</p>
          {date ?
            <p className="mb-0 tx-12">{moment(date).format("DD MMM YYYY")}</p> : ''
          }

        </div>
      </div>
    );
  };
  return (<>
    <Header innerHeader={'innerHeader'}></Header>
    <BrowserView>


      <AccountSubHeader breadcrumbtitle={'Overview'}></AccountSubHeader>
      <section className="section-gap-medium">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <AccountSidebar ></AccountSidebar>
            </div>
            <div className="col-lg-9">
              <div className="section-title mb-30">
                <h2>Overview</h2>
              </div>
              <div>
                
                {!spinnerLoading ? (
                  orderData?.length > 0 ? (
                    <div className="row ">
                       <h5>Recent Order</h5>
                      {orderData.map((value, index) => (
                        <div className="col-lg-8" key={index}>
                            <div className="order-box">
                            <div className="info">
                              <div className="info-delivery">
                                <h5 className="mb-3 fw400">Shipping Address</h5>
                                <h6>{value.trans_user_name}</h6>
                                <p className="tx-14 mb-1">{value.trans_delivery_address}</p>
                                <p className="tx-14 mb-1">Email Id : {value.trans_user_email}</p>
                                <p className="tx-14 mb-0">Mobile No : {value.trans_user_mobile}</p>
                             
                                <div className="row bs-wizard mt-5">
                                  {value.trans_status === 0 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="disabled" date="" statustext="Item Picked Up" />
                                      <Step status="disabled" date="" statustext="Shipped" />
                                      <Step status="disabled" date="" statustext="Delivered" />
                                    </>
                                  )}
                                   {value.trans_status === 1 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="active" date={value.trans_confirmed_date} statustext="Confirmed" />
                                      <Step status="disabled" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                      <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                  {value.trans_status === 2 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="complete" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                      <Step status="active" date={value.trans_shiped_date} statustext="Shipped" />
                                      <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                  
                                  {value.trans_status === 3 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="complete" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                      <Step status="complete" date={value.trans_shiped_date} statustext="Shipped" />
                                      <Step status="complete" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                  {value.trans_status === 4 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="complete" date={value.trans_cancelled_date} statustext="Cancelled" />
                                    </>
                                  )}
                                  {value.trans_status === 5 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="active" date={value.trans_onhold_date} statustext="On Hold" />
                                      <Step status="disabled" date={value.trans_shiped_date} statustext="Shipped" />
                                      <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="bcode">
                              <div className="orderid-box mb-5">
                                <h6 className="mb-0">ORDER ID</h6>
                                <p className="mb-0 tx-14 fw400">{value.trans_order_number}</p>
                              </div>
                              <p className="mb-0 tx-13">ORDER ON</p>
                              <p className="tx-14">
                                {moment(value.trans_datetime).format("ddd, DD MMM YYYY")}
                                <br />
                                {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}
                              </p>
                              <p><a class="btn btn-underline-primary p-0" href={`/order-detail/${value.trans_order_number}`} >View Details<i class="ri-arrow-right-line"></i></a></p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="recentbox">
                      <h5>Recent Order</h5>
                      <p>You haven't placed any orders yet.</p>
                    </div>
                  )
                ) : null}

              </div>


            </div>
          </div>
        </div>
      </section>


    </BrowserView>
    <MobileView>
      <AccountSubHeader breadcrumbtitle={'Overview'}></AccountSubHeader>
      <section className="section-gap-small">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <AccountSidebar></AccountSidebar>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gap-small">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

              <div className="section-title mb-30">
                <h2>Overview</h2>
              </div>
              {!spinnerLoading ? (
                  orderData?.length > 0 ? (
                    <div className="row">
                       <h5>Recent Order</h5>
                      {orderData.map((value, index) => (
                        <div className="col-lg-8" key={index}>
                           <div className="order-box">
                            <div className="info">
                              <div className="info-delivery">
                              <h6 className="mb-0">ORDER ID</h6>
                              <p className="mb-0 tx-14 fw400">{value.trans_order_number}</p>
                              <p className="mb-0 tx-13">ORDER ON {moment(value.trans_datetime).format("ddd, DD MMM YYYY")} {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}</p>
                                <h5 className="mb-3 fw400 mt-20">Shipping Address</h5>
                                <h6>{value.trans_user_name}</h6>
                                <p className="tx-14 mb-1">{value.trans_delivery_address}</p>
                                <p className="tx-14 mb-1">Email Id : {value.trans_user_email}</p>
                                <p className="tx-14 mb-0">Mobile No : {value.trans_user_mobile}</p>
                             
                                <div className="row bs-wizard mt-5">
                                  {value.trans_status === 0 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="disabled" date="" statustext="Item Picked Up" />
                                      <Step status="disabled" date="" statustext="Shipped" />
                                      <Step status="disabled" date="" statustext="Delivered" />
                                    </>
                                  )}
                                   {value.trans_status === 1 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="active" date={value.trans_confirmed_date} statustext="Confirmed" />
                                      <Step status="disabled" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                      <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                  {value.trans_status === 2 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="complete" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                      <Step status="active" date={value.trans_shiped_date} statustext="Shipped" />
                                      <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                  
                                  {value.trans_status === 3 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="complete" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                      <Step status="complete" date={value.trans_shiped_date} statustext="Shipped" />
                                      <Step status="complete" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                  {value.trans_status === 4 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="complete" date={value.trans_cancelled_date} statustext="Cancelled" />
                                    </>
                                  )}
                                  {value.trans_status === 5 && (
                                    <>
                                      <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                      <Step status="active" date={value.trans_onhold_date} statustext="On Hold" />
                                      <Step status="disabled" date={value.trans_shiped_date} statustext="Shipped" />
                                      <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                    </>
                                  )}
                                </div>
                                <a class="btn btn-underline-primary p-0 mt-20" href={`/order-detail/${value.trans_order_number}`} >View Details<i class="ri-arrow-right-line"></i></a>
                              </div>
                            </div>
                          
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="recentbox">
                <h5>Recent Order</h5>
                <p>You haven't placed any orders yet.</p>
              </div>
                  )
                ) : null}
              


            </div>
          </div>
        </div>
      </section>

    </MobileView>
    <Footer></Footer>


  </>)
}

export default MyAccount