import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BrowserView, MobileView } from 'react-device-detect';
import Accordion from 'react-bootstrap/Accordion';


const ProductTabs = ({producttabs}) => {
    return (<>
    <BrowserView>
    {producttabs && producttabs.length>0 ?<>
        <div className="productTabs">
            <Tabs
                defaultActiveKey={producttabs[0].tab_name}
                id="justify-tab-example"
            >
                {producttabs && producttabs?.map((item, index)=>(
                    item.tab_description&&(
                        <Tab eventKey={item.tab_name} title={item.tab_name} key={index}>
                        <div dangerouslySetInnerHTML={{__html:item.tab_description}}></div>
                    </Tab>
                    )
                ))}
            </Tabs>
        </div>
    </>:''}
    
    </BrowserView>

    <MobileView>

    {producttabs && producttabs?.length > 0 && 
            <Accordion defaultActiveKey={producttabs[0].tab_name}>
            {producttabs && producttabs?.map((item, index) => (
                item.tab_description && (
                <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.tab_name}</Accordion.Header>
                    <Accordion.Body dangerouslySetInnerHTML={{__html:item.tab_description}}></Accordion.Body>
                </Accordion.Item>
             )
            ))}
            </Accordion>        
        }
 
    </MobileView>
  
    </>)
}
export default ProductTabs


