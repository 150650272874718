import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { ApiService } from '../Service/apiservices';
import DataContext from '../Element/context';
import { validEmail, validNumber } from '../Element/Regex';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const AddressModal = ({ editAddDetails }) => {
    const navigate = useNavigate()
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_state_name: "",
        ua_state_id: "",
        ua_city_id: "",
        ua_city_name: "",
        ua_apartment: "",
        ua_default_address: "",
        ua_complete_address: '',
        ua_country_id: '',
        ua_address_type: "Home",
        ua_address_type_other: "",
    });
    const [firstnameFocused, setfirstnameFocused] = useState(false);
    const [lastnameFocused, setlastnameFocused] = useState(false);
    const [mobilenumberFocused, setmobilenumberFocused] = useState(false);
    const [addressFocused, setaddressFocused] = useState(false);
    const [postalFocused, setpostalFocused] = useState(false);
    const [stateFocused, setstateFocused] = useState(false);
    const [cityFocused, setcityFocused] = useState(false);
    const [countryFocused, setcountryFocused] = useState(false);
    const [addressTypeFocused, setaddressTypeFocused] = useState(false);
    const [addressTypeOtherFocused, setaddressTypeOtherFocused] = useState(false);

    const [billaddressFocused, setBilladdressFocused] = useState(false);
    const contextValues = useContext(DataContext);
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
           
            getCountryData();
            getStateData()
            if(editAddDetails?.ua_state_id){
 getCityData(editAddDetails?.ua_state_id)
            }
            
           
        }
        didMountRef.current = false;
    }, []);

    const addressModal = () => {
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
    }
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [countryData, setcountryData] = useState([]);
    const [stateData, setstateData] = useState([]);
    const [cityData, setcityData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const onTodoAddChange = (e) => {
        setErrorMessage('')
        const { name, value } = e.target;
        setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if(name=='ua_state_id'){
            setUserAddressDetails((prevState) => ({
                ...prevState,
                ['ua_city_id']: '',
            }));
            getCityData(value)
            
        }

        if (value !== '') {
            e.target.style.border = '';
        }
    }


    useEffect(() => {
        if (editAddDetails) {
            setUserAddressDetails({
                ua_id: editAddDetails.ua_id,
                ua_fname: editAddDetails.ua_fname,
                ua_lname: editAddDetails.ua_lname,
                ua_pincode: editAddDetails.ua_pincode,
                ua_complete_address: editAddDetails.ua_complete_address,
                ua_state_name: editAddDetails.ua_state_name,
                ua_city_name: editAddDetails.ua_city_name,
                ua_state_id:editAddDetails.ua_state_id,
                ua_city_id:editAddDetails.ua_city_id,
                ua_default_address: "",
                ua_country_id: editAddDetails.ua_country_id,
                ua_mobile: editAddDetails.ua_mobile,
                ua_address_type: editAddDetails.ua_address_type,
                ua_address_type_other: editAddDetails.ua_address_type_other,
                ua_apartment: editAddDetails.ua_apartment,
                
            });
        } else {
            setUserAddressDetails({
                ua_id: 0,
                ua_fname: "",
                ua_lname: "",
                ua_mobile: "",
                ua_pincode: "",
                ua_state_name: "",
                ua_city_name: "",
                ua_default_address: "",
                ua_complete_address: '',
                ua_country_id: '',
                ua_state_id: '',
                ua_city_id: '',
                ua_address_type: "Home",
                ua_address_type_other: "",
                ua_apartment: "",

            });
        }
    }, [editAddDetails]);



    const getCountryData = () => {
        ApiService.fetchData("getCountryData").then((res) => {
            if (res.status == "success") {
                setcountryData(res.data);
            }
        });
    };
    const getStateData = () => {
        ApiService.fetchData("getallStates").then((res) => {
            if (res.status == "success") {
                setstateData(res.data);
            }
        });
    };
    const getCityData = (stateid) => {
        const dataString={
            state_id:stateid
        }
        ApiService.postData("getCityByState", dataString).then((res) => {
            if (res) {
                setcityData(res);
            }
        });
    };

    const handleAddressProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {
                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (userAddressDetails.ua_fname == '') {
                setErrorMessage('Please Enter First Name');
                return;
            }

            if (userAddressDetails.ua_mobile == '') {
                setErrorMessage('Please Enter Mobile NUmber');
                return;
            }
            if (!validNumber.test(userAddressDetails.ua_mobile)) {
                setErrorMessage("Please Enter Valid Mobile Number");
                return false;
            }
            if (userAddressDetails.ua_apartment == '') {
                setErrorMessage('Please Enter  Appartment , Suite etc');
                return;
            }
            if (userAddressDetails.ua_complete_address == '') {
                setErrorMessage('Please Enter  Address');
                return;
            }
            if (userAddressDetails.ua_pincode == '') {
                setErrorMessage('Please enter post code');
                return;
            }
            if (userAddressDetails.ua_pincode.length!==6 ) {
                setErrorMessage('Please enter valid post code');
                return;
            }
            if (userAddressDetails.ua_country_id === "") {
                toast.error("Please Select Country");
                return false;
            }
            if (userAddressDetails.ua_state_id === "") {
                toast.error("Please Select State");
                return false;
            }
            if (userAddressDetails.ua_city_id === "") {
                toast.error("Please Select City");
                return false;
            }
            // if (userAddressDetails.ua_city_name == "") {
            //     setErrorMessage('Plese Enter City');
            //     return false;
            // }

            // if (userAddressDetails.ua_state_name == "") {
            //     setErrorMessage('Plese Enter State');
            //     return false;
            // }

            if (userAddressDetails.ua_address_type === "") {
                toast.error("Please select Address type");
                return false;
            }

            if (userAddressDetails.ua_address_type === "Other") {
                if (userAddressDetails.ua_address_type_other === "") {
                    toast.error("Please enter Other");
                    return false;
                }
            }

            setspinnerLoading(true);
            ApiService.postData("/userAddressProcess", userAddressDetails).then(
                (res) => {
                    if (res.status == "success") {
                        setSuccessMessage(res.message);
                        setspinnerLoading(false);
                        window.location.reload();
                    }
                    else if (res.message == 'Session expired') {
                        localStorage.removeItem('USER_TOKEN')
                        setErrorMessage(res.message)
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 500)
                    }
                    else {
                        setErrorMessage(res.message);
                        setspinnerLoading(false);
                    }
                }
            ).catch(() => { });
        }
    };
    return (<>
        <Modal className="addressModal" show={contextValues.toggleAddressModal} onHide={(e) => { addressModal() }}>
            <button type="button" className="addressModal-Close" onClick={() => { addressModal() }}><i className="ri-close-line ri-xl"></i></button>
            <div className='addressModal-content'>
            <div className="row g-3">
                <div className="col-lg-12">
                    <div className="cartSectionTitle">
                        <h5 className="mb-0 tx-18">Add New Address</h5>
                        <p className='tx-14 mb-0'>Add your home and office addresses and enjoy faster checkout</p>
                    </div>
                </div>
                {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                )}
                {successMessage && (
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                    </div>
                )}
                <div className="col-lg-6">
                    <div className="flotting-group">
                        <label className={`${firstnameFocused || userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                        <input type="text" className={` required form-control`}
                            name="ua_fname" value={userAddressDetails.ua_fname}
                            placeholder="First Name"
                            onChange={(e) => onTodoAddChange(e)}
                            onFocus={(e) => setfirstnameFocused(true)}
                            onBlur={(e) => setfirstnameFocused(false)}
                        ></input>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="flotting-group">
                        <label className={lastnameFocused || userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                        <input type="text" className="required form-control" placeholder="Last Name"
                            onFocus={(e) => setlastnameFocused(true)}
                            onBlur={(e) => setlastnameFocused(false)}
                            name="ua_lname" value={userAddressDetails.ua_lname}
                            onChange={(e) => onTodoAddChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group">
                        <label className={mobilenumberFocused || userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                        <input type="number" className="required form-control" name="ua_mobile" value={userAddressDetails.ua_mobile}
                            onChange={(e) => onTodoAddChange(e)}
                            placeholder="Mobile Number"
                            onFocus={(e) => setmobilenumberFocused(true)}
                            onBlur={(e) => setmobilenumberFocused(false)}></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group">
                        <label className={billaddressFocused || userAddressDetails.ua_apartment ? "label" : ""}>House No, Appartment , Suite etc</label>
                        <input type="text" className="required form-control" name="ua_apartment"
                            placeholder="House No, Appartment , Suite etc"
                            onFocus={(e) => setBilladdressFocused(true)}
                            onBlur={(e) => setBilladdressFocused(false)}
                            value={userAddressDetails.ua_apartment}
                            onChange={(e) => onTodoAddChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="flotting-group">
                        <label className={addressFocused || userAddressDetails.ua_complete_address ? "label" : ""}>Street Address</label>
                        <input type="text" className="required form-control" name="ua_complete_address"
                            placeholder="Street Address"
                            onFocus={(e) => setaddressFocused(true)}
                            onBlur={(e) => setaddressFocused(false)}
                            value={userAddressDetails.ua_complete_address}
                            onChange={(e) => onTodoAddChange(e)}
                        ></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group">
                        <label className={postalFocused || userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                        <input type="number" className="required form-control"
                            placeholder="Postal Code" name="ua_pincode"
                            onFocus={(e) => setpostalFocused(true)}
                            onBlur={(e) => setpostalFocused(false)}
                            value={userAddressDetails.ua_pincode}
                            onChange={(e) => onTodoAddChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group">
                        <label className={countryFocused || userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                        <select value={userAddressDetails.ua_country_id} onChange={(e) => onTodoAddChange(e)} onFocus={(e) => setcountryFocused(true)} onBlur={(e) => setcountryFocused(false)} name='ua_country_id' className='required form-control'>
                            <option value=''>Select Country</option>
                            {countryData.length > 0 && countryData.map((value) => (
                                <option value={value.country_id}>{value.country_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group">
                        <label className={stateFocused || userAddressDetails.ua_state_id ? "label" : ""}>State</label>
                        <select value={userAddressDetails.ua_state_id} onChange={(e) => onTodoAddChange(e)}
                            onFocus={(e) => setstateFocused(true)}
                            onBlur={(e) => setstateFocused(false)}
                            name='ua_state_id' className='required form-control'>
                            <option value=''>Select State</option>
                            {stateData.length > 0 && stateData.map((value) => (
                                <option value={value.state_id}>{value.state_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group">
                        <label className={cityFocused || userAddressDetails.ua_city_id ? "label" : ""}>City</label>
                        <select value={userAddressDetails.ua_city_id} onChange={(e) => onTodoAddChange(e)}
                            onFocus={(e) => setcityFocused(true)}
                            onBlur={(e) => setcityFocused(false)}
                            name='ua_city_id' className='required form-control'>
                            <option value=''>Select City</option>
                            {cityData.length > 0 && cityData.map((value) => (
                                <option value={value.cities_id}>{value.cities_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
             
                <div className={userAddressDetails.ua_address_type === 'Other' ? "col-lg-6" : "col-lg-12"}>
                    <div className="flotting-group">
                        <label className={addressTypeFocused || userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                        <select name="ua_address_type" className="form-control required"
                            value={userAddressDetails.ua_address_type}
                            onChange={(e) => onTodoAddChange(e)}
                            onFocus={(e) => setaddressTypeFocused(true)}
                            onBlur={(e) => setaddressTypeFocused(false)}>
                            <option value="">Address Type</option>
                            <option value="Home">Home</option>
                            <option value="Work">Work</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                {userAddressDetails.ua_address_type === 'Other' &&
                    <div className='col-lg-6'>
                        <div className="flotting-group">
                            <label className={addressTypeOtherFocused || userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                            <input type="text" name="ua_address_type_other" className="form-control required"
                                value={userAddressDetails.ua_address_type_other}
                                onChange={(e) => onTodoAddChange(e)}
                                onFocus={(e) => setaddressTypeOtherFocused(true)}
                                onBlur={(e) => setaddressTypeOtherFocused(false)}
                                placeholder="Other"
                            />
                        </div>
                    </div>
                }
                <div className='col-lg-12'>
                    <button type="button" className="btn btn-primary btn-full"
                        onClick={(e) => { handleAddressProcess(e) }}
                    ><span>Save & Continue</span></button>
                </div>

            </div>
            </div>
        </Modal>

    </>)
}


export default AddressModal