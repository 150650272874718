import { useContext, useState } from "react"
import DataContext from "../Element/context"
import Modal from 'react-bootstrap/Modal';
import constant from "../Service/constant";
import { ApiService } from "../Service/apiservices";
import { validEmail } from "../Element/Regex";
import Alert from 'react-bootstrap/Alert';
const DropHint = ({ ProductsData }) => {
  console.log(ProductsData, 'kdlddk')
  const contextValues = useContext(DataContext)
  const dropHintModal = () => {
    contextValues.setToggledropHintModal(!contextValues.toggleDropHintModal)
  }
  const [Inputfields, setInputfields] = useState({
    reciepient_name: '',
    reciepient_mail: '',
    user_name: '',
    user_mail: '',

  });
  const [usermail, setUsermail] = useState('')
  const [showpreview, setshowpreview] = useState(false)
  const [loader, setloader] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const getSignUp = () => {
    if (Inputfields.reciepient_name == '') {
      setErrorMessage('Please enter the Reciepient Name')
      return
    }
    if (Inputfields.reciepient_mail == '') {
      setErrorMessage('Please enter the Reciepient Email')
      return
    }
    if (!validEmail.test(Inputfields.reciepient_mail)) {
      setErrorMessage('Invalid Email Format')
      return false;
    }
    if (Inputfields.user_name == '') {
      setErrorMessage('Please enter the Sender Name')
      return
    }

    if (Inputfields.user_mail == '') {
      setErrorMessage('Please enter the Sender Email')
      return
    }
    if (!validEmail.test(Inputfields.user_mail)) {
      setErrorMessage('Invalid Email Format')
      return false;
    }

    const dataString = {
      recipents_name: Inputfields?.reciepient_name,
      recipents_email: Inputfields?.reciepient_mail,
      recipents_user_name: Inputfields?.user_name,
      recipents_user_email: Inputfields?.user_mail,
      recipents_product_id: ProductsData?.product_id
    }
    setloader(true)
    setshowpreview(true)
    ApiService.postData('/recipentprocess', dataString).then((res) => {
      if (res?.status == 'success') {
        setSuccessMessage(res.message)
        setTimeout(() => {
          setSuccessMessage('')
          setloader(false)
          setErrorMessage('')
          setUsermail('')
          dropHintModal()
        }, 1000)
      }
      else if (res.status == 'error') {
        setErrorMessage(res.message)
        setTimeout(() => {
          setSuccessMessage('')
          setloader(false)
          setErrorMessage('')
          setUsermail('')
          dropHintModal()
        }, 1000)
      }
      else {
        setErrorMessage('')
        setTimeout(() => {
          setSuccessMessage('')
          setloader(false)
          setErrorMessage('')
        }, 1000)
      }
    })
  }
  const onInputChange = (e) => {
    setErrorMessage('')
    setshowpreview(false)
    const { name, value } = e.target;
    setInputfields(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };


  return (<>
    <Modal show={contextValues.toggleDropHintModal} onHide={(e) => dropHintModal()} className="drophint">
      <button type="button" className="drophint-Close" onClick={() => { dropHintModal() }}><i className="ri-close-line ri-xl"></i></button>
      <div className="drophint-content">
        <div className="row align-items-center">
          <div className="col-lg-6 border-right">
            <div className="drophint-content-left">
              <p>Hint..., Hint....,</p>
              {Inputfields?.reciepient_name !== '' && showpreview && <p>{Inputfields?.reciepient_name}</p>}
              <p>The Universe told us that </p>
              {Inputfields?.user_name !== '' && showpreview && <p>{Inputfields?.user_name}</p>}
              <p>has been dreaming about something from by PADMAKSHYA and we thought you want to know.......</p>
              <img src={ProductsData?.product_image} style={{ width: '100%' }}></img>
            </div>
            <div>

            </div>

          </div>
          <div className="col-lg-6">
            <div className="p-2">
              {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
              {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
              <div className="form-group mb-15">
                <input type="text" placeholder="Recipient's Name" name="reciepient_name" value={Inputfields.reciepient_name} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
              </div>
              <div className="form-group mb-15">
                <input type="text" placeholder="Recipients's Email" name="reciepient_mail" value={Inputfields.reciepient_mail} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
              </div>
              <div className="form-group mb-15">
                <input type="text" placeholder="User Name" name="user_name" value={Inputfields.user_name} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
              </div>
              <div className="form-group mb-15">
                <input type="text" placeholder="User Email" name="user_mail" value={Inputfields.user_mail} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
              </div>

              <div className="d-flex justify-content-between">
                <button class="btn btn-lg btn-primary btn-full mb-20 mt-20 me-3" onClick={() => { getSignUp() }} disabled={loader}>{loader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : 'SEND'}</button>
                <button class="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { setshowpreview(true) }} >PREVIEW</button></div>

            </div>
          </div>
        </div>
      </div>
    </Modal>

  </>)
}

export default DropHint