
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useCallback, useState, useRef, useContext } from "react";
import { Autoplay } from "swiper/modules";
import Modal from 'react-bootstrap/Modal';
import DataContext from "../Element/context";

const VideoModal = ({ dataObj, activevideo }) => {
  const contextValues = useContext(DataContext);
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(activevideo); // Tracks the active slide index
  const [mutedVideos, setMutedVideos] = useState(
    dataObj?.has_many_gallery?.map((_, index) => index !== activevideo)
  ); // Initially mute all videos except the active one

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const Videomodal = () => {
    contextValues.setToggleVideoModal(!contextValues.toggleVideoModal);
  };

  const toggleMute = (index) => {
    setMutedVideos((prevMuted) => 
      prevMuted.map((muted, idx) => (idx === index ? !muted : muted))
    );
  };

  const handleSlideChange = (swiper) => {
    const newIndex = swiper.activeIndex;
    setActiveIndex(newIndex);
    // Mute all videos except the active one
    setMutedVideos((prevMuted) =>
      prevMuted.map((_, index) => index !== newIndex)
    );
  };

  return (
    <>
      <Modal show={contextValues.toggleVideoModal} onHide={Videomodal} className="videoGalleryModal">
        <button className="modal-close" type="button" onClick={Videomodal}>
          <i className="ri-close-line ri-xl"></i>
        </button>
        {dataObj?.has_many_gallery?.length > 0 && (
          <>
            <div className="videoGalleryModalSwiper">
              <Swiper
                loop={false}
                spaceBetween={15}
                ref={sliderRef}
                centeredSlides={true}
                roundLengths={true}
                breakpoints={{
                  320: { slidesPerView: 1 },
                  480: { slidesPerView: 1 },
                  640: { slidesPerView: 1 },
                  768: { slidesPerView: 1 },
                  1024: { slidesPerView: 3 },
                  1280: { slidesPerView: 3 },
                }}
                initialSlide={activevideo}
                onSlideChange={handleSlideChange} // Handle slide change
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                autoplay={
                  dataObj.has_autoplay_speed
                    ? {
                        delay: dataObj.home_slider_auto_play_speed,
                        disableOnInteraction: false,
                      }
                    : false
                }
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              >
                {dataObj?.has_many_gallery?.map((value, index) => (
                  <SwiperSlide key={index}>
                    <div className="videoPopProduct">
                      <div className="videoPopProduct-inner">
                        <div className="volumicon" onClick={() => toggleMute(index)}>
                          <i className={mutedVideos[index] ? "ri-volume-mute-fill" : "ri-volume-up-fill"}></i>
                        </div>

                        {value.home_gallery_video_url ? (
                          <video
                            src={value.home_gallery_video_url}
                            autoPlay
                            loop
                            muted={mutedVideos[index]}
                            playsInline
                            style={{ width: "100%", height: "100%" }}
                          ></video>
                        ) : (
                          <iframe
                            width="100%"
                            height="100%"
                            src={`${value.home_gallery_orvideo_url}?autoplay=1&mute=${mutedVideos[index] ? 1 : 0}&loop=1&controls=0&rel=0`}
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            controls={0}
                            rel="0"
                            autoPlay='1'
                            loop='1'
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {dataObj.home_slider_hide_arrow !== 1 && (
                <div className="swiper-arrow">
                  <div className="prev-arrow" onClick={handlePrev}>
                    <i className="ri-arrow-left-s-line"></i>
                  </div>
                  <div className="next-arrow" onClick={handleNext}>
                    <i className="ri-arrow-right-s-line"></i>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default VideoModal;
