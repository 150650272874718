import { useNavigate } from "react-router-dom"
import Header from "../../../Component/Header"
import Footer from "../../../Component/Footer"
import ServiceFooter from "../../../Component/Footer/services_footer"
import { useState , useEffect, useRef } from "react"
import { ApiService } from "../../../Component/Service/apiservices"



const Unsubscribe=()=>{
    const navigate= useNavigate()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')

    
    const [successmessage, setsuccessmessage] = useState('')
    const [errormessage, seterrormessage] = useState('')
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            getunsubscribe()
        }
        didMountRef.current = false
    },[])

    const getunsubscribe = () => {
        const dataString = {
            id: id,
            
        }
        ApiService.loginProccessPostData('unsubscribe', dataString).then((res) => {
            if (res.status == 'success') {
                setsuccessmessage(res.message)
            } else {
                seterrormessage(res.message)
            }
        })
    }
    
    return(<>
      <Header innerHeader={'innerHeader'}></Header>
        <section>
            <div className="container-fluid px-0">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/img/loginpadma.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 ">
                    <div className="loginrigisterbox">
                                <div className="tx-center pt-5 mb-20">
                                    <img src="/img/unsubscribe.png" className="wd-250 mb-20" ></img>
                                    <h6 style={{ textAlign: 'center' }}>You have been Unscubscribe to the PADMAKSHYA</h6>
                                </div>
                                
                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}>Go To Home</button>
                            </div>

                    </div>
                </div>
            </div>
        </section>
        <ServiceFooter></ServiceFooter>
        <Footer></Footer>
    </>)
}


export default Unsubscribe