import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useCallback, useState, useRef, useContext } from "react";
import { Autoplay } from "swiper/modules";
import VideoModal from "../../../Component/Modal/video_modal";
import DataContext from "../../../Component/Element/context";

const VideoGallery = ({ dataObj }) => {
    const contextValues= useContext(DataContext)
    const [activevideo, setactivevideo]= useState({})
   
    const sliderRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);
   
    const onClickonVideo=(value)=>{
        setactivevideo(value)
        contextValues.setToggleVideoModal(!contextValues.toggleVideoModal)
    }
    return (<>
        {dataObj?.has_many_gallery?.length > 0 && (
            <div className="videoSwiper">
                <Swiper
                    loop={true}
                    spaceBetween={15}
                    ref={sliderRef}
                    className=""
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    autoplay={
                        dataObj.has_autoplay_speed
                            ? {
                                delay: dataObj.home_slider_auto_play_speed,
                                disableOnInteraction: false,
                            }
                            : false
                    }
                    breakpoints={{
                        320: {
                            slidesPerView: dataObj.home_slider_no_slides,
                        },
                        480: {
                            slidesPerView: dataObj.home_slider_no_slides,
                        },
                        640: {
                            slidesPerView: dataObj.home_slider_no_slides,
                        },
                        768: {
                            slidesPerView: dataObj.home_slider_no_slides,
                        },
                        1024: {
                            slidesPerView: dataObj.home_slider_no_slides,
                        },
                        1280: {
                            slidesPerView: dataObj.home_slider_no_slides,
                        },
                    }}

                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}

                >
                    {dataObj?.has_many_gallery?.map((value, index) => (

                        <SwiperSlide key={index}>
                            <div className="fillaboutsec-media withvideolink videosec"  onClick={()=>{onClickonVideo(index)}}>
                                {value.home_gallery_video_url ? <video src={value.home_gallery_video_url} autoPlay="autoplay" loop muted playsInline style={{ width: '100%', height: '100%' }}></video> : <iframe
                                    width="100%"
                                    height="100%"
                                    src={`${value.home_gallery_orvideo_url}?autoplay=1&mute=1&loop=1&controls=0&rel=0`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    controls={0}
                                    rel="0"
                                    autoPlay='1'
                                    loop='1'

                                ></iframe>}
                              {value.home_gallery_caption_text && (<h2>{value.home_gallery_caption_text}</h2>)}  

                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {dataObj.home_slider_hide_arrow!==1 && (
                    <div className='swiper-arrow'>
                         <div className="prev-arrow" onClick={handlePrev}><i class="ri-arrow-left-s-line"></i></div>
                         <div className="next-arrow" onClick={handleNext}><i class="ri-arrow-right-s-line"></i></div>
                    </div>
                )}

            </div>

        )}


        <VideoModal activevideo={activevideo} dataObj={dataObj} ></VideoModal>

    </>)
}

export default VideoGallery