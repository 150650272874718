import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../Service/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import ProductBox from "./product_box";
import { useNavigate } from "react-router-dom"; 
import { Pagination} from 'swiper/modules';
function CategoryWiseProducts({ dataObj }) {
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  const [homeCategoriesData, setHomeCategoriesData] = useState([]);
  const [loading, setLoading] = useState();
  const slidervertRef = useRef(null);
  const handleVertPrev = useCallback(() => {
    if (!slidervertRef.current) return;
    slidervertRef.current.swiper.slidePrev();
  }, []);

  const handleVertNext = useCallback(() => {
    if (!slidervertRef.current) return;
    slidervertRef.current.swiper.slideNext();
  }, [])

  useEffect(() => {
    if (didMountRef.current) {
      getHomeCategoryData();
    }
    didMountRef.current = false;
  }, [homeCategoriesData]);

  const getHomeCategoryData = () => {
    setLoading(true);
    const dataString = {
      data: dataObj,
    };
    ApiService.postData("categoryWiseProducts", dataString).then((res) => {
      if (res.status == "success") {
        setHomeCategoriesData(res.categoriesData);
        setLoading(false);
      }
    });
  };
  
  const resetFilter = (url) => {
    navigate(url)
  }

  return (
    <>
      {loading == true ? (
        <>
          {Array.from({ length: 2 }).map((_, index) => (
            <section className="sec-gap-top" key={index}>
              <div className="container">
                <div className="row">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className="col-lg-3 col-6">
                      <div className="product">
                        <figure className="product-media">
                          <Skeleton variant="rectangular" width={280} height={315} />
                        </figure>
                        <div className="product-details">
                          <h3 className="product-name">
                            <Skeleton variant="text" width={150} />
                          </h3>
                          <div className="product-price">
                            <Skeleton variant="text" width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </>
      ) : homeCategoriesData.length > 0 ? (
        <>
          {homeCategoriesData.map((value, index) => {
            if (value.catProducts.length > 0) {
              if (dataObj.home_display_type === 2) {
                return (
                  <div key={index}>
                    {dataObj.home_display_name === 0 ? <div className="section-title d-flex align-items-center justify-content-between mb-4">
                      <h3 className="mb-0">{value.cat_name}</h3>
                      <a href={"javascript:void(0)"}
                        onClick={() => resetFilter("/list/category/" + value.cat_slug)}
                      >
                        View All <i className="d-icon-arrow-right"></i>
                      </a>
                    </div>: ""}
                    
                    <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                      {value.catProducts.map((subvalue, indexProduct) => {
                        return (
                          <ProductBox productValue={subvalue} classType="product mb-5" key={indexProduct} />
                        );
                      })}
                    </div>
                  </div>
                );
              } else {
                if (dataObj.home_slider_display_type === 2) {
                  return (
                    <div key={index}>
                      {dataObj.home_display_name === 0 ? <div className="section-title d-flex align-items-center justify-content-between mb-4">
                      <h3 className="mb-0">{value.cat_name}</h3>
                      <a href={"javascript:void(0)"}
                        onClick={() => resetFilter("/list/category/" + value.cat_slug)}
                      >
                        View All <i className="d-icon-arrow-right"></i>
                      </a>
                    </div>: ""}
                      <div className="hslider">
                      <Swiper
                        loop="false"
                        slidesPerView={dataObj.home_column}
                        ref={slidervertRef}
                        spaceBetween={15}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        }}
                      >
                        {value.catProducts.map((subvalue, indexProduct) => {
                          return (
                            <SwiperSlide key={indexProduct}>
                              <ProductBox productValue={subvalue} classType="product" key={indexProduct} />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      {/* <div className='swiper-arrow'>
                        <div className="prev-arrow" onClick={handleVertPrev} ><img src='/img/arrow-up.svg'></img></div>
                        <div className="next-arrow" onClick={handleVertNext}><img src='/img/arrow-down.svg'></img></div>
                      </div> */}
                      </div>
                   
                 
                    </div>
                  );
                } else {
                  return (
                    <div className="vSlider">
                      <Swiper
                        direction={'vertical'}
                        slidesPerView={dataObj.home_column}
                        modules={[Pagination]}
                        ref={slidervertRef}
                      >
                        {value.catProducts.map((subvalue, indexProduct) => {
                          return (
                            <SwiperSlide key={indexProduct}>
                              <ProductBox productValue={subvalue} classType="product tx-center" key={indexProduct} />  
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <div className='swiper-arrow'>
                        <div className="prev-arrow" onClick={handleVertPrev} ><img src='/img/arrow-up.svg'></img></div>
                        <div className="next-arrow" onClick={handleVertNext}><img src='/img/arrow-down.svg'></img></div>
                      </div>
                    </div>
                  )
                }
              }
            }
          })}
        </>
      ) : null}
    </>
  );
}
export default CategoryWiseProducts;
