import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { BrowserView, MobileView } from "react-device-detect";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { ApiService } from "../../../Component/Service/apiservices";
import React, { useEffect, useRef, useState} from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
const HomeBanner = () => {
  const navigate= useNavigate()
  const [sliderData, setSliderData] = useState([])
  const [slidermobileData, setSliderMobileData] = useState([])
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const didMountRef = useRef(true);
  const sliderRef = useRef(null);
  useEffect(() => {
    if (didMountRef.current) {

      getSliderData()
    }
    didMountRef.current = false
  })

  const getSliderData = () => {
    ApiService.fetchData("top-banner-list").then((res) => {
      if (res.status == "success") {
        setSliderData(res.resTopBannerData)
        setSliderMobileData(res.resMobileBannerData)
        setTimeout(() => {
          setSpinnerLoading(false)
        }, 500)
      } else {
        setTimeout(() => {
          setSpinnerLoading(false)
        }, 500)
      }
    }).catch(() => {
      setTimeout(() => {
        setSpinnerLoading(false)
      }, 500)
    })
  }

  const onBannerClick = (e, value) => {
    e.preventDefault()
    if (value.cat_slug !== null && value.slider_category!==null) {
      window.location.href=`/collections/category/${value?.category?.cat_slug}`
    }
    else if (value.tag_slug !==null && value.slider_tags!==null){
        window.location.href=`/collections/tag/${value?.tag?.tag_slug}`
    }
    else if(value.slider_url!==null){
        window.open(value.slider_url, '_blank');
    }

  }



  return (<>

    <BrowserView>
      {spinnerLoading ? <>
        <div className='heroSlider'>
          <Swiper>
            <SwiperSlide>
              <div className='media-fullscreen-wrap'>
                <div className='media-fullscreen-img'>
                  <Skeleton height={'1100px'}></Skeleton>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </> : <>
        {sliderData && sliderData.length > 0 ? <>
          <div className='heroSlider'>
            <Swiper
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              ref={sliderRef}
            >
              {sliderData.map((slide, index) => (
                <SwiperSlide key={index}>
                  {slide.slider_view === 2 ? (
                    <div className='video-fullscreen-wrap' onClick={(e)=>{onBannerClick(e, slide)}}>
                      <div className="video-fullscreen-video">

                        <video src={slide.slider_video} autoPlay="autoplay" loop muted playsInline ></video>
                      </div>
                      {slide.slider_desc !== null ? (
                        <div dangerouslySetInnerHTML={{ __html: slide?.slider_desc }}></div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className='media-fullscreen-wrap' onClick={(e)=>{onBannerClick(e, slide)}}>
                      <div className='media-fullscreen-img' >
                        <img src={slide.slider_image} alt="slider_image" />
                      </div>
                      {slide.slider_desc != null ? (
                        <div className="heroSlider-content">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12">
                                <div dangerouslySetInnerHTML={{ __html: slide?.slider_desc }}></div>
                              </div>
                            </div>
                            </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </> : null}
      </>
      }
    </BrowserView>
    <MobileView>
      {spinnerLoading ? <>
        <div className='heroSlider'>
          <Swiper>
            <SwiperSlide>
              <div className='video-fullscreen-wrap'>
                <div className='video-fullscreen-video'>
                  <Skeleton height={'900px'}></Skeleton>
                </div>

              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </> : <>
        <div className='heroSlider'>
          <Swiper
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            ref={sliderRef}
          >
            {slidermobileData.map((slide, index) => (
              <SwiperSlide key={index}>
                {slide.slider_view === 2 ? (
                  <div className='video-fullscreen-wrap'>
                    <div className="video-fullscreen-video">

                      <video src={slide.slider_video} autoPlay="autoplay" loop muted playsInline ></video>
                    </div>
                    {slide.slider_desc != null ? (
                      <div dangerouslySetInnerHTML={{ __html: slide?.slider_desc }}></div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className='media-fullscreen-wrap'>
                    <div className='media-fullscreen-img'>
                      <img src={slide.slider_image} alt="slider_image" />
                    </div>
                    {slide.slider_desc != null ? (
                       <div className="heroSlider-content">
                       <div className="container">
                         <div className="row">
                           <div className="col-lg-12">
                             <div dangerouslySetInnerHTML={{ __html: slide?.slider_desc }}></div>
                           </div>
                         </div>
                         </div>
                     </div>
                     
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>}
    </MobileView>
  </>)
}

export default HomeBanner