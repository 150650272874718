import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Component/Service/apiservices";
import { useContext, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddressModal from "../../Component/Modal/address_modal";
import DataContext from "../../Component/Element/context";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import AccountSubHeader from "../../Component/Header/account_subheader";


const MyAddress = () => {
    const contextValues = useContext(DataContext)
    const [isLoading, setisLoading] = useState(false)
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_apartment: "",
        ua_default_address: "",
        ua_complete_address: '',
        ua_country_id: '',
        ua_address_type: "Home",
        ua_address_type_other: "",
    });
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const [userAddressList, setuserAddressList] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            getuserAddress()
        }
        didMountRef.current = false;
    }, []);
    const getuserAddress = () => {
        setisLoading(true)
        ApiService.fetchData('get-user-address').then((res) => {
            if (res.status == 'success') {
                setuserAddressList(res.resUserAddress)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setisLoading(false)
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }
        }).catch(() => {
            setisLoading(false)
        })

    }
    const addressModal = () => {
        setUserAddressDetails({
            ua_id: 0,
            ua_fname: "",
            ua_lname: "",
            ua_email: "",
            ua_mobile: "",
            ua_pincode: "",
            ua_state_name: "",
            ua_city_name: "",
            ua_apartment: "",
            ua_default_address: "",
            ua_complete_address: '',
            ua_country_id: '',
            ua_address_type: "Home",
            ua_address_type_other: "",
            ua_state_id:'',
            ua_city_id:''
        })

        setTimeout(() => {
            contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
        }, 500)

    }
    const editAddress = (value) => {
        setUserAddressDetails({
            ua_id: value.ua_id,
            ua_fname: value.ua_fname,
            ua_lname: value.ua_lname,
            ua_complete_address: value.ua_complete_address,
            ua_email: value.ua_email,
            ua_mobile: value.ua_mobile,
            ua_pincode: value.ua_pincode,
            ua_house_no: value.ua_house_no,
            ua_apartment: value.ua_apartment,
            ua_state_name: value.ua_state_name,
            ua_city_name: value.ua_city_name,
            ua_default_address: value.ua_default_address,
            ua_country_id: value.ua_country_id,
            ua_address_type: value.ua_address_type,
            ua_address_type_other: value.ua_address_type_other,
             ua_state_id:value.ua_state_id,
            ua_city_id:value.ua_city_id
        })
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
    }
    const deleteaddress = (value) => {
        if (window.confirm("Are you sure about delete the address ?")) {
            const dataString = {
                addrid: value,
            };
            ApiService.postData("removeAddress", dataString).then((res) => {
                if (res.status == "success") {
                    getuserAddress();
                }
                else {
                    toast.error(res?.message)
                }
            });
        } else {

        }
    };
    const navigateback = (e) => {
        e.preventDefault()

        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (<>
 <Header innerHeader={'innerHeader'}></Header>
        <BrowserView>
            <AccountSubHeader breadcrumbtitle={'Addressess'}></AccountSubHeader>
            <section className="section-gap-medium">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <AccountSidebar></AccountSidebar>
                        </div>
                        <div className="col-lg-9">
                            <div className="section-title mb-30">
                                <h2>Addresses</h2>
                            </div>
                           
                                {isLoading ? <>
                                   <div className="row g-3">
                                    {[...Array(4)].map((item, index) => {
                                        return (<>
                                            <div className="col-lg-6" key={index}>
                                                <div className="addressbox mb-10">
                                                    <div className="addressbox-body">
                                                        <h6 className="mb-1 fw400 tx-uppercase"><Skeleton width={'200px'} ></Skeleton></h6>
                                                        <p className="mb-1"><Skeleton width={'100px'} ></Skeleton></p>
                                                        <p className="mb-0"><Skeleton width={'100px'} ></Skeleton></p>
                                                    </div>
                                                    <div className="addressbox-footer d-flex mt-3"><Skeleton width={'100px'} height={'50px'} className="btn  btn-small me-3"></Skeleton><Skeleton width={'100px'} height={'50px'} className="btn  btn-small"></Skeleton></div>
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                    </div>
                                </> : userAddressList && userAddressList?.length > 0 ? <>
                                <div className="row g-3">
                                    {userAddressList?.map((address, index) => {
                                        return (<>
                                            <div className="col-lg-6" key={index}>
                                                <div className="addressbox mb-10">
                                                <p className="mb-1">{address?.ua_address_type} {address?.ua_default_address == 1 ? '(Default)' : ''}</p>
                                                <h6 className="mb-2 fw500">{address?.ua_fname ? address?.ua_fname : ''} {' '} {address?.ua_lname ? address?.ua_lname : ''} {''}</h6>
                                                <p className="mb-1">{address?.ua_apartment}, {address?.ua_complete_address}, {address?.ua_city_name},{address?.ua_state_name} {address?.ua_pincode}</p>      
                                                <p className="mb-1">Mobile No:{address?.ua_mobile}</p>
                                               {address?.ua_address_type_other && <p className="mb-1">Other:{address?.ua_address_type_other}</p> } 
                                                <div className="mt-10">
                                                    <button className="btn btn-underline-primary btn-ulg p-0 me-3" onClick={() => { editAddress(address) }}>Edit</button>
                                                    <button className="btn btn-underline-primary btn-ulg p-0" onClick={() => { deleteaddress(address.ua_id) }}>Delete</button></div>
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                 </div>
                                </> : <>
                                    <div>
                                        <h4>No Address Found!</h4>
                                        <a class="btn btn-underline-primary btn-ulg p-0 mt-10" href="javascript:void(0)" onClick={() => { addressModal() }}><i class="ri-add-circle-line"></i> Add Address </a>
                                    </div>
                                </>}

                           {userAddressList && userAddressList?.length > 0 && <a className="btn btn-underline-primary btn-ulg p-0 mt-20" href="javascript:void(0)" onClick={(e) => { addressModal() }} ><i class="ri-add-circle-line"></i> Add Address </a>}
                            
                        </div>
                    </div>
                </div>
            </section>

        </BrowserView>
        <MobileView>
            <AccountSubHeader breadcrumbtitle={'Addressess'}></AccountSubHeader>
            <section className="section-gap-small">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <AccountSidebar></AccountSidebar>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-gap-small">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">

                            <div className="section-title mb-30">
                                <h2>Addressess</h2>
                            </div>
                            {isLoading ? <>
                                {[...Array(4)].map((item, index) => {
                                    return (<>
                                        <div className="col-lg-12" key={index}>
                                            <div className="addressbox mb-2">
                                                <div className="addressbox-body">
                                                    <h6 className="mb-1 fw400 tx-uppercase"><Skeleton width={'200px'} ></Skeleton></h6>
                                                    <p className="mb-1"><Skeleton width={'100px'} ></Skeleton></p>
                                                    <p className="mb-0"><Skeleton width={'100px'} ></Skeleton></p>
                                                </div>
                                                <div className="addressbox-footer d-flex mt-3"><Skeleton width={'100px'} height={'50px'} className="btn  btn-small me-3"></Skeleton><Skeleton width={'100px'} height={'50px'} className="btn  btn-small"></Skeleton></div>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </> : userAddressList && userAddressList?.length > 0 ? <>
                                {userAddressList?.map((address, index) => {
                                    return (<>
                                        <div className="col-lg-12" key={index}>
                                        <div className="addressbox mb-10">
                                                <p className="mb-1">{address?.ua_address_type} {address?.ua_default_address == 1 ? '(Default)' : ''}</p>
                                                <h6 className="mb-2 fw500">{address?.ua_fname ? address?.ua_fname : ''} {' '} {address?.ua_lname ? address?.ua_lname : ''} {''}</h6>
                                                <p className="mb-1">{address?.ua_apartment}, {address?.ua_complete_address}, {address?.ua_city_name},{address?.ua_state_name} {address?.ua_pincode}</p>      
                                                <p className="mb-1">Mobile No:{address?.ua_mobile}</p>
                                               {address?.ua_address_type_other && <p className="mb-1">Other:{address?.ua_address_type_other}</p> } 
                                                <div className="mt-10">
                                                    <button className="btn btn-underline-primary btn-ulg p-0 me-3" onClick={() => { editAddress(address) }}>Edit</button>
                                                    <button className="btn btn-underline-primary btn-ulg p-0" onClick={() => { deleteaddress(address.ua_id) }}>Delete</button></div>
                                                </div>
                                        </div>
                                    </>)
                                })}
                                <p><a className="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={(e) => { addressModal() }} >Add Address +</a></p>

                            </> : <>
                                <div>
                                    <h4>No Address Found!</h4>
                                    <p><a class="btn btn-underline-primary btn-ulg p-0" href="javascript:void(0)" onClick={() => { addressModal() }}><i class="ri-add-circle-line"></i> Add Address </a></p>
                                </div>
                            </>}

                        </div>
                    </div>
                </div>
            </section>



        </MobileView>
        <Footer></Footer>
        {contextValues?.toggleAddressModal && <AddressModal editAddDetails={userAddressDetails}></AddressModal>}

    </>)
}

export default MyAddress 