import Footer from "../../../Component/Footer"
import Header from "../../../Component/Header"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const SizeGuide = () => {
    return (<>
        <Header innerHeader={'innerHeader'}></Header>
        <section className="section-gap-small bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title tx-center">
                            <h2 className="mb-0">Sizing Chart</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-gap-medium tx-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sizetabs">
                            <Tabs
                                defaultActiveKey="Rings"
                                id="justify-tab-example"
                            >
                                <Tab eventKey="Rings" title="Rings">
                                    <div className="row">
                                        <div className="col-lg-6 border-right">
                                            <div className="sizetabsbox">
                                                <p>METHOD 1</p>
                                                <h2>If you <span>have</span> a ring</h2>
                                                <p>Take a ring that fits you perfectly.</p>
                                                <img className="wd-120" src="/img/guide1.webp" />
                                                <p>Measure the largest distance between the inner edges of ring to get the inner diameter in mm.</p>
                                                <img class="wd-120" src="/img/guide2.webp" />
                                                <h5>Now you have the inner diameter of the ring, use the following chart to determine your size.</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="sizetabsbox">
                                                <p>METHOD 2</p>
                                                <h2>If you <span>don’t have</span> a ring</h2>
                                                <p>Wind a thread around your finger and cut it where the ends meet. You can also do this with a strip of thin paper.</p>
                                                <img className="wd-120" src="/img/guide3.webp" />
                                                <p>Straighten it to measure on a ruler in mm.</p>
                                                <img class="wd-100" src="/img/guide4.webp" />
                                                <h5>Now you have the circumference of the ring, use the following chart to determine your size.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center" >
                                        <div className="col-8">
                                            <div class="chart-image">
                                                <img class="img-fluid" src="/img/chart.webp" />
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="Loose Bracelet" title="Loose Bracelet">
                                    <div className="row">
                                        <div className="col-lg-6 border-right">
                                        <div className="sizetabsbox">
                                                <p>METHOD 2</p>
                                                <h2>If you <span>have</span> a bracelet</h2>
                                                <p>Measure the length of the bracelet in inches.</p>
                                                <img class="wd-120" src="/img/guide5.webp" />
                                                <p>Use the following chart to determine your bracelet size.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="sizetabsbox">
                                                <p>METHOD 2</p>
                                                <h2>If you <span>don’t have</span> a bracelet</h2>
                                                <p>Wind a thread around your wrist and cut it where the ends meet and add 1-2 cm for added comfort.</p>
                                                <img className="wd-120" src="/img/guide6.webp" />
                                                <p>Measure the length of thread with a ruler and use the following chart to determine your bracelet size.</p>
                                                <img class="wd-100" src="/img/guide7.webp" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center" >
                                        <div className="col-8">
                                            <div class="chart-image">
                                                <img class="img-fluid" src="/img/chart2.webp" />
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="Oval Bracelet" title="Oval Bracelet">
                                    <div className="row">
                                    <div className="col-lg-6 border-right">
                                        <div className="sizetabsbox">
                                                <p>METHOD 2</p>
                                                <h2>If you <span>have</span> a bracelet</h2>
                                                <p>Measure the length of the bracelet in inches.</p>
                                                <img class="wd-120" src="/img/guide8.webp" />
                                                <p>Measure the length and breadth of bangle and use the following chart to determine your bracelet size.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="sizetabsbox">
                                                <p>METHOD 2</p>
                                                <h2>If you <span>don’t have</span> a bracelet</h2>
                                                <p>Wind a thread around your wrist and cut it where the ends meet and add 1-2 cm for added comfort.</p>
                                                <img className="wd-120" src="/img/guide9.webp" />
                                                <p>Measure the length of thread with a ruler and use the following chart to determine your bracelet size.</p>
                                                <img class="wd-100" src="/img/guide10.webp" />
                                            </div>
                                        </div>
                                        
                                      
                                    </div>
                                    <div className="row d-flex justify-content-center" >
                                        <div className="col-8">
                                            <div class="chart-image">
                                                <img class="img-fluid" src="/img/chart3.webp" />
                                            </div>
                                        </div>

                                    </div>
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default SizeGuide