import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';

const CustomAccordions = ({ dataObj }) => {

    return (<>
        {dataObj?.accordions && dataObj?.accordions?.length > 0 && 
            <Accordion defaultActiveKey={0}>
            {dataObj?.accordions && dataObj?.accordions?.map((item, index) => (
                item.home_ta_content && (
                <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item?.home_ta_title}</Accordion.Header>
                    <Accordion.Body dangerouslySetInnerHTML={{ __html: item?.home_ta_content }}></Accordion.Body>
                </Accordion.Item>
             )
            ))}
            </Accordion>        
        }
    </>)
}

export default CustomAccordions