import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { ApiService } from '../Service/apiservices';

function OrderCancelModal({ transId, show, closeModal }) {
    const [orderReason, setOrderReason] = useState("")
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleClose = () => {
        closeModal(false);
    };

    const handleCancelOrder = () => {
        setErrorMessage('');
        setSuccessMessage('');
        if (orderReason === '') {
            setErrorMessage('Please choose cancel reason');
            return false;
        }
        const dataString = {
            trans_id: transId,
            order_reason:orderReason
        }
        ApiService.postData("cancel-order", dataString).then((res) => {
            if (res.data.status === "success") {
              setSuccessMessage(res.data.notification);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
             } else {
              setErrorMessage(res.data.notification);
             }
          });

    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <button type="button" className="cart-close" onClick={handleClose}><i className="ri-close-line"></i></button>
                <Modal.Body>

                    <h5 className="tx-theme mb-1">Reason For Cancellation</h5>
                    <p className="tx-color-02 tx-12">Please choose cancellation reason</p>
                    {errorMessage && (
                        <Alert variant="danger">{errorMessage}</Alert>
                    )}
                    {successMessage && (
                        <Alert variant="success">{successMessage}</Alert>
                    )}
                    <div className="cancleform">
                        <ul>
                            <li><input type="radio" name="cancel_reason" value="Order Created by Mistake" onChange={(e) => setOrderReason(e.target.value)} /><span>Order Created by Mistake</span></li>
                            <li><input type="radio" name="cancel_reason" value="Item(s) Would Not Arrive on Time" onChange={(e) => setOrderReason(e.target.value)} /><span>Item(s) Would Not Arrive on Time</span></li>
                            <li><input type="radio" name="cancel_reason" value="Shipping Cost too high" onChange={(e) => setOrderReason(e.target.value)} /><span>Shipping Cost too high</span></li>
                            <li><input type="radio" name="cancel_reason" value="Item Price Too High" onChange={(e) => setOrderReason(e.target.value)} /><span>Item Price Too High</span></li>
                            <li><input type="radio" name="cancel_reason" value="Found Cheaper Somewhere Else" onChange={(e) => setOrderReason(e.target.value)} /><span>Found Cheaper Somewhere Else</span></li>
                            <li><input type="radio" name="cancel_reason" value="Need to change Shipping Address" onChange={(e) => setOrderReason(e.target.value)} /><span>Need to change Shipping Address</span></li>
                            <li><input type="radio" name="cancel_reason" value="Need to change Shipping Speed" onChange={(e) => setOrderReason(e.target.value)} /><span>Need to change Shipping Speed</span></li>
                            <li><input type="radio" name="cancel_reason" value="Need to change Billing Address" onChange={(e) => setOrderReason(e.target.value)} /><span>Need to change Billing Address</span></li>
                            <li><input type="radio" name="cancel_reason" value="Need to change Payment Method" onChange={(e) => setOrderReason(e.target.value)} /><span>Need to change Payment Method</span></li>
                        </ul>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary btnw-200" onClick={handleCancelOrder}>Submit</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OrderCancelModal;