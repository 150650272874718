
import { useContext } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DataContext from "../Element/context";
const AccountSubHeader=({breadcrumbtitle})=>{
    const contextValues= useContext(DataContext)
   
    return(<>
     <div className="subHeader subHeaderAccount">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                        <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>{breadcrumbtitle}</Breadcrumb.Item>
                            </Breadcrumb>
                          {contextValues?.rowUserData ? <h3 className="mb-0">Welcome, {contextValues?.rowUserData?.user_fname}!</h3>:''}  
                        </div>
                    </div>
                </div>
            </div>
    
    </>)
}

export default AccountSubHeader