
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../../Component/Header";
import Footer from "../../../Component/Footer";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import DataContext from "../../../Component/Element/context";

const JewleryCare = () => {
    const navigate = useNavigate();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [jewelleryCareData, setjewelleryCareData] = useState([]);
    const [addActive, setaddActive] = useState(0);
    const contentRefs = useRef([]);
    const contextValues = useContext(DataContext);

    useEffect(() => {
        if (didMountRef.current) {
            getJewelleryCareData();
        }
        didMountRef.current = false;

        // Add scroll event listener to handle active class on scroll
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [jewelleryCareData]);

    const getJewelleryCareData = () => {
        ApiService.fetchData("jewelryCare")
            .then((res) => {
                if (res.status === "success") {
                    setjewelleryCareData(res.jewelryData);
                    setSpinnerLoading(false);
                } else {
                    setSpinnerLoading(false);
                }
            })
            .catch(() => {
                setSpinnerLoading(false);
            });
    };

    const handleScroll = () => {
        const offset = 180; // Adjust offset for better accuracy
        contentRefs.current.forEach((ref, index) => {
            if (ref) {
                const rect = ref.getBoundingClientRect();
                if (rect.top <= offset && rect.bottom > offset) {
                    setaddActive(index);
                }
            }
        });
    };

    const scrollToContent = (index) => {
        if (contentRefs.current[index]) {
            setaddActive(index);
            const topOffset = contentRefs.current[index].offsetTop - 100; // Adjust the offset value as needed
            window.scrollTo({
                top: topOffset,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            {jewelleryCareData && jewelleryCareData.length > 0 ? (
                <section className="section-gap-md">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="jewlerysiderbar">
                                    <ul className="careTablist">
                                        {jewelleryCareData.map((item, index) => (
                                            <li
                                                key={index}
                                                onClick={() => scrollToContent(index)}
                                                className={addActive === index ? "active" : ""}
                                            >
                                                {item.jewelry_title}
                                            </li>
                                        ))}
                                    </ul>
                                    <hr className="mb-50 mt-50"></hr>
                                    <div>
                                        <h5>CONTACT US_ </h5>
                                        <p>For help with your purchase, Our team are available</p>
                                        <p>Monday-Saturday, 11am-6pm</p>
                                        <ul className="contactlist">
                                            <li>
                                                Call:{" "}
                                                <span>
                                                    <a href={`tel:${contextValues.settingData.admin_support_mobile}`}>
                                                        {contextValues.settingData?.admin_support_mobile}
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                WhatsApp:{" "}
                                                <span>
                                                    <a href={`https://wa.me/${contextValues.settingData.admin_whatsapp_no}`}>
                                                        {contextValues.settingData?.admin_whatsapp_no}
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                Email:{" "}
                                                <span>
                                                    <a href={`mailto:${contextValues.settingData.admin_support_email}`}>
                                                        {contextValues.settingData.admin_support_email}
                                                    </a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                {jewelleryCareData.map((item, index) => (
                                    <div
                                        key={index}
                                        className="mb-40"
                                        ref={(el) => (contentRefs.current[index] = el)}
                                    >
                                        <div className="section-title mb-20">
                                            <h5>{item.jewelry_title}</h5>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: item.jewelry_description }}></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            ) : null}
        </>
    );
};

export default JewleryCare;
